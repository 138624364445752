import React from 'react';
import { Link } from 'react-router-dom';
import { FEATURE_FLAGS } from '../featureFlags';
import styles from './Homepage.module.css';

function Homepage() {
  const games = [
    ...(FEATURE_FLAGS.SHOW_FOOTBALL ? [{
      name: 'Football - 5 Scorers',
      path: '/football',
      links: [
        { name: 'Make Selections', path: '/make-selections' },
        { name: 'My Selections', path: '/my-selections' },
        { name: 'View All Selections', path: '/all-selections' },
      ]
    }] : []),
    ...(FEATURE_FLAGS.SHOW_AMERICAN_FOOTBALL ? [{
      name: 'American Football - LMS',
      path: '/american-football/lms',
      links: [
        { name: 'Make Selections', path: '/make-selections' },
        { name: 'My Selections', path: '/my-selections' },
        { name: 'View All Selections', path: '/all-selections' },
      ]
    }] : []),
    ...(FEATURE_FLAGS.SHOW_NFLSC ? [{
      name: 'American Football - Super Contest',
      path: '/american-football/sc',
      links: [
        { name: 'Make Selections', path: '/make-selections' },
        { name: 'My Selections', path: '/my-selections' },
        { name: 'Weekly Selections', path: '/weekly-selections' },
        { name: 'Leaderboard', path: '/leaderboard' },
      ]
    }] : []),
  ];

  return (
    <div className={styles.homepage}>
      <h1>Welcome to Predictions</h1>
      <div className={styles.gameSelection}>
        {games.map((game, index) => (
          <div key={index} className={styles.gameOption}>
            <h2>{game.name}</h2>
            {game.links.map((link, linkIndex) => (
              <Link key={linkIndex} to={`${game.path}${link.path}`} className={styles.gameLink}>
                {link.name}
              </Link>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}

export default Homepage;