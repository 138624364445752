import React, { useState } from 'react';
import { useAuth } from '../AuthContext';
import { resetPasswordLoggedIn } from '../services/api';
import styles from './Profile.module.css';

function Profile() {
  const { user } = useAuth();
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');

  const handleResetPassword = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      setMessage('Passwords do not match');
      return;
    }
    try {
      await resetPasswordLoggedIn(newPassword);
      setMessage('Password reset successfully');
      setNewPassword('');
      setConfirmPassword('');
    } catch (error) {
      setMessage('Failed to reset password. Please try again.');
    }
  };

  return (
    <div className={styles.profileContainer}>
      <h2>Profile</h2>
      <div className={styles.userInfo}>
        <p><strong>Email:</strong> {user.email}</p>
        <p><strong>Display Name:</strong> {user.displayName}</p>
      </div>
      
      <h3>Reset Password</h3>
      <form onSubmit={handleResetPassword}>
        <div className={styles.inputGroup}>
          <label htmlFor="newPassword">New Password</label>
          <input
            type="password"
            id="newPassword"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            required
          />
        </div>
        <div className={styles.inputGroup}>
          <label htmlFor="confirmPassword">Confirm Password</label>
          <input
            type="password"
            id="confirmPassword"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
        </div>
        <button type="submit" className={styles.resetButton}>Reset Password</button>
      </form>
      {message && <p className={styles.message}>{message}</p>}
    </div>
  );
}

export default Profile;