import React, { useState, useEffect } from 'react';
import { fetchUserSelections, updateUserSelections, getTeams, getPlayers, getCurrentGame } from '../../services/api';
import Card from '../common/Card';
import LoadingSpinner from '../common/LoadingSpinner';
import styles from './MySelections.module.css';

function MySelections() {
  const [selections, setSelections] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [editingSelection, setEditingSelection] = useState(null);
  const [teams, setTeams] = useState([]);
  const [players, setPlayers] = useState({});
  const [currentGame, setCurrentGame] = useState(null);
  const [showErrorModal, setShowErrorModal] = useState(false);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    try {
      const [selectionsData, teamsData, playersData, gameData] = await Promise.all([
        fetchUserSelections(),
        getTeams(),
        getPlayers(),
        getCurrentGame()
      ]);
      console.log('Selections:', selectionsData);
      console.log('Teams:', teamsData);
      console.log('Players:', playersData);
      console.log('Current Game:', gameData);

      setSelections(selectionsData);
      setTeams(teamsData);
      setCurrentGame(gameData);
      
      const playersByTeam = playersData.reduce((acc, player) => {
        const teamName = player.team?.name || 'Unassigned';
        if (!acc[teamName]) {
          acc[teamName] = [];
        }
        acc[teamName].push(player);
        return acc;
      }, {});
      console.log('Players by team:', playersByTeam);
      setPlayers(playersByTeam);
      
      setIsLoading(false);
    } catch (err) {
      console.error('Error loading data:', err);
      setError('Failed to load data. Please try again later.');
      setShowErrorModal(true);
      setIsLoading(false);
    }
  };

  const handleEdit = (selectionSet, index) => {
    if (isDeadlinePassed) {
      setError('Deadline has passed. Selections can no longer be edited.');
      setShowErrorModal(true);
      return;
    }
    setEditingSelection({ ...selectionSet, editIndex: index });
  };

  const handleSave = async () => {
    setError(null);
    setSuccessMessage(null);

    if (isDeadlinePassed) {
      setError('Deadline has passed. Selections can no longer be edited.');
      setShowErrorModal(true);
      return;
    }

    // Check for duplicate player selections
    const players = editingSelection.selections.map(s => s.player);
    if (new Set(players).size !== players.length) {
      setError('You can only select each player once.');
      setShowErrorModal(true);
      return;
    }

    try {
      await updateUserSelections(editingSelection._id, editingSelection.selections);
      setEditingSelection(null);
      setSuccessMessage('Selection updated successfully!');
      loadData();
    } catch (err) {
      console.error('Error updating selection:', err);
      setError('Failed to update selection. Please try again.');
      setShowErrorModal(true);
    }
  };

  const handleTeamChange = (teamName) => {
    const newSelections = [...editingSelection.selections];
    newSelections[editingSelection.editIndex] = { team: teamName, player: '' };
    setEditingSelection({ ...editingSelection, selections: newSelections });
  };

  const handlePlayerChange = (playerName) => {
    const newSelections = [...editingSelection.selections];
    
    if (newSelections.some((s, idx) => idx !== editingSelection.editIndex && s.player === playerName)) {
      setError('You can only select a player once.');
      setShowErrorModal(true);
      return;
    }
    
    newSelections[editingSelection.editIndex].player = playerName;
    setEditingSelection({ ...editingSelection, selections: newSelections });
    setError(null);
  };

  const closeErrorModal = () => {
    setShowErrorModal(false);
    setError(null);
  };

  const isDeadlinePassed = currentGame && new Date() > new Date(currentGame.deadline);

  const renderSelections = (selections, currentGame, handleEdit, editingSelection, handleSave, teams, players, handleTeamChange, handlePlayerChange) => {
    return selections.map((selectionSet) => (
      <div key={selectionSet._id} className={styles.selectionSet}>
        {selectionSet.game._id !== currentGame._id && (
          <h3 className={styles.gameTitle}>Game: {selectionSet.game.name}</h3>
        )}
        <p className={styles.submittedDate}>Submitted: {new Date(selectionSet.createdAt).toLocaleString()}</p>
        <div className={styles.selectionsContainer}>
          {selectionSet.selections.map((selection, idx) => (
            <div key={idx} className={styles.selectionRow}>
              <div className={styles.selectionInfo}>
                <span className={styles.label}>Team:</span>
                {editingSelection && editingSelection._id === selectionSet._id && editingSelection.editIndex === idx ? (
                  <select 
                    value={editingSelection.selections[idx].team}
                    onChange={(e) => handleTeamChange(e.target.value)}
                    className={styles.select}
                  >
                    <option value="">Select Team</option>
                    {teams.map(team => (
                      <option key={team._id} value={team.name}>{team.name}</option>
                    ))}
                  </select>
                ) : (
                  <span className={styles.value}>{selection.team}</span>
                )}
              </div>
              <div className={styles.selectionInfo}>
                <span className={styles.label}>Player:</span>
                {editingSelection && editingSelection._id === selectionSet._id && editingSelection.editIndex === idx ? (
                  <select 
                    value={editingSelection.selections[idx].player}
                    onChange={(e) => handlePlayerChange(e.target.value)}
                    className={styles.select}
                  >
                    <option value="">Select Player</option>
                    {players[editingSelection.selections[idx].team]?.map(player => (
                      <option key={player._id} value={player.name}>{player.name}</option>
                    ))}
                  </select>
                ) : (
                  <span className={styles.value}>{selection.player}</span>
                )}
              </div>
              {!selectionSet.game.isArchived && !isDeadlinePassed && (
                <div className={styles.selectionActions}>
                  {editingSelection && editingSelection._id === selectionSet._id && editingSelection.editIndex === idx ? (
                    <button onClick={handleSave} className={`${styles.button} ${styles.editButton}`}>Save</button>
                  ) : (
                    <button onClick={() => handleEdit(selectionSet, idx)} className={`${styles.button} ${styles.editButton}`}>Edit</button>
                  )}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    ));
  };

  if (isLoading) return <LoadingSpinner />;

  const currentSelections = selections.filter(s => s.game._id === currentGame?._id);
  const archivedSelections = selections.filter(s => s.game._id !== currentGame?._id);
  
  return (
    <Card>
      {successMessage && <p className={styles.success}>{successMessage}</p>}
      
      {currentGame && (
        <div className={styles.currentGame}>
          <h2 className={styles.sectionTitle}>Current Game: {currentGame.name}</h2>
          <p className={styles.deadlineInfo}>Deadline: {new Date(currentGame.deadline).toLocaleString()}</p>
        </div>
      )}
      
      {currentSelections.length > 0 ? (
        <div className={styles.selectionGroup}>
          {renderSelections(currentSelections, currentGame, handleEdit, editingSelection, handleSave, teams, players, handleTeamChange, handlePlayerChange)}
        </div>
      ) : (
        <div className={styles.noCurrentSelections}>
          <p className={styles.warningMessage}>
            You haven't made any selections for this gameweek yet. 
            {!isDeadlinePassed ? " Don't forget to submit your selections before the deadline!" : " The deadline has passed for this gameweek."}
          </p>
        </div>
      )}
      
      {archivedSelections.length > 0 && (
        <div className={styles.selectionGroup}>
          <h2 className={styles.sectionTitle}>Archived Selections</h2>
          {renderSelections(archivedSelections, currentGame, handleEdit, editingSelection, handleSave, teams, players, handleTeamChange, handlePlayerChange)}
        </div>
      )}

      {selections.length === 0 && <p className={styles.noSelections}>No selections found.</p>}

      {showErrorModal && (
        <div className={styles.modal}>
          <div className={styles.modalContent}>
            <p className={styles.error}>{error}</p>
            <button onClick={closeErrorModal} className={styles.button}>Close</button>
          </div>
        </div>
      )}
    </Card>
  );
}

export default MySelections;