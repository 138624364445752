import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import styles from './LoginPrompt.module.css';

function LoginPrompt() {
  const location = useLocation();

  return (
    <div className={styles.promptContainer}>
      <h2>Login Required</h2>
      <p>You need to be logged in to access this page.</p>
      <div className={styles.buttonContainer}>
        <Link to="/login" state={{ from: location }} className={styles.button}>
          Login
        </Link>
        <Link to="/register" state={{ from: location }} className={styles.button}>
          Register
        </Link>
      </div>
    </div>
  );
}

export default LoginPrompt;