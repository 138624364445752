import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { useAuth } from '../AuthContext';
import GameNavigation from '../components/navigation/GameNavigation';
import NflscLeaderboard from '../components/nflsc/Leaderboard';
import NflscMakeSelections from '../components/nflsc/MakeSelections';
import NflscMySelections from '../components/nflsc/MySelections';
import NflscWeeklySelections from '../components/nflsc/WeeklySelections';
import LoginPrompt from '../components/common/LoginPrompt';
import ProtectedRoute from '../components/common/ProtectedRoute';

const routes = [
  { name: 'Make Selections', path: '/make-selections' },
  { name: 'My Selections', path: '/my-selections' },
  { name: 'Weekly Selections', path: '/weekly-selections' },
  { name: 'Leaderboard', path: '/leaderboard' },
];

function NflscRoutes() {
  const { user } = useAuth();
  return (
    <div>
      <GameNavigation routes={routes} basePath="/american-football/sc" />
      <Routes>
        <Route index element={<NflscLeaderboard />} />
        <Route path="make-selections" element={user ? <NflscMakeSelections /> : <LoginPrompt />} />
        <Route path="my-selections" element={<ProtectedRoute><NflscMySelections /></ProtectedRoute>} />
        <Route path="weekly-selections" element={user ? <NflscWeeklySelections /> : <LoginPrompt />} />
        <Route path="leaderboard" element={<NflscLeaderboard />} />
      </Routes>
    </div>
  );
}

export default NflscRoutes;