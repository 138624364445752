import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { useAuth } from '../AuthContext';
import AmericanFootballLayout from '../components/american-football/AmericanFootballLayout';
import AmericanFootballHome from '../pages/americanFootball/AmericanFootballHome';
import MakeSelections from '../components/american-football/MakeSelections';
import MySelections from '../components/american-football/MySelections';
import AllSelections from '../components/american-football/AllSelections';
import LoginPrompt from '../components/common/LoginPrompt';
import ProtectedRoute from '../components/common/ProtectedRoute';

function AmericanFootballLmsRoutes() {
  const { user } = useAuth();
  return (
    <Routes>
      <Route element={<AmericanFootballLayout />}>
        <Route index element={<AmericanFootballHome />} />
        <Route path="make-selections" element={user ? <MakeSelections /> : <LoginPrompt />} />
        <Route path="my-selections" element={<ProtectedRoute><MySelections /></ProtectedRoute>} />
        <Route path="all-selections" element={user ? <AllSelections /> : <LoginPrompt />} />
      </Route>
    </Routes>
  );
}

export default AmericanFootballLmsRoutes;