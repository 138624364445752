import React, { useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import styles from './GameNavigation.module.css';

const GameNavigation = ({ routes, basePath }) => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();

  return (
    <nav className={styles.gameNav}>
      <button className={styles.mobileMenuButton} onClick={() => setIsOpen(!isOpen)}>
        Game Menu
      </button>
      <ul className={`${styles.navList} ${isOpen ? styles.open : ''}`}>
        {routes.map(route => (
          <li key={route.path}>
            <NavLink
              to={`${basePath}${route.path}`}
              className={({ isActive }) => `${styles.gameNavItem} ${isActive ? styles.active : ''}`}
              onClick={() => setIsOpen(false)}
            >
              {route.name}
            </NavLink>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default GameNavigation;