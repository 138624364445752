import React from 'react';
import { NavLink, Outlet } from 'react-router-dom';
import styles from './AmericanFootballLayout.module.css';

function AmericanFootballLayout() {
    return (
      <div className={styles.layout}>
        <nav className={styles.nav}>
          <span className={styles.gameIndicator}>American Football - SC</span>
          <NavLink to="/american-football/lms/make-selections" className={({isActive}) => isActive ? styles.activeLink : styles.link}>
            Make Selections
          </NavLink>
          <NavLink to="/american-football/lms/my-selections" className={({isActive}) => isActive ? styles.activeLink : styles.link}>
            My Selections
          </NavLink>
          <NavLink to="/american-football/lms/weekly-selections" className={({isActive}) => isActive ? styles.activeLink : styles.link}>
            Weekly Selections
          </NavLink>          
        </nav>
        <div className={styles.content}>
          <Outlet />
        </div>
      </div>
    );
  }

export default AmericanFootballLayout;