import React, { useState, useEffect } from 'react';
import { 
  createNewGame, 
  createNewNflGame,
  createNewNflscGame,
  getCurrentGame, 
  fetchCurrentNflGameFixtures,
  fetchCurrentNflscGameFixtures,
  updateGameDeadline, 
  syncFootballData, 
  syncNflData, 
  getTeams, 
  getPlayers, 
  updateScores, 
  markMatchComplete, 
  updateNflMatchResult,
  processNflscResults
} from '../services/api';
import styles from './Admin.module.css';

function Admin() {
  console.log('Admin component rendering');

  const [currentGame, setCurrentGame] = useState(null);
  const [currentNflGame, setCurrentNflGame] = useState(null);
  const [nflDeadline, setNflDeadline] = useState('');
  const [deadline, setDeadline] = useState('');
  const [newGameName, setNewGameName] = useState('');
  const [newNflGameName, setNewNflGameName] = useState('');
  const [error, setError] = useState(null);
  const [syncMessage, setSyncMessage] = useState(null);
  const [teams, setTeams] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState('');
  const [players, setPlayers] = useState([]);
  const [scoringPlayers, setScoringPlayers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [nflSyncMessage, setNflSyncMessage] = useState(null);
  const [nflMatches, setNflMatches] = useState([]);
  const [selectedNflMatch, setSelectedNflMatch] = useState('');
  const [nflHomeScore, setNflHomeScore] = useState('');
  const [nflAwayScore, setNflAwayScore] = useState('');
  const [currentNflscGame, setCurrentNflscGame] = useState(null);
  const [newNflscGameName, setNewNflscGameName] = useState('');
  const [nflscDeadline, setNflscDeadline] = useState('');

  useEffect(() => {
    console.log('Admin useEffect running');
    const fetchInitialData = async () => {
      try {
        await fetchCurrentGame();
        await fetchCurrentNflGame();
        await fetchCurrentNflscGame();
        await fetchTeams();
      } catch (err) {
        console.error('Error fetching initial data:', err);
        setError('Some data failed to load. The page may have limited functionality.');
      } finally {
        setLoading(false);
      }
    };
  
    fetchInitialData();
  }, []);

  const fetchCurrentGame = async () => {
    try {
      console.log('Fetching current game');
      const game = await getCurrentGame();
      console.log('Current game:', game);
      setCurrentGame(game);
      setDeadline(game ? new Date(game.deadline).toISOString().slice(0, 16) : '');
    } catch (err) {
      console.error('Failed to fetch current game:', err);
      setCurrentGame(null);
    }
  };

  const fetchCurrentNflGame = async () => {
    try {
      const { currentGame, matches } = await fetchCurrentNflGameFixtures();
      setCurrentNflGame(currentGame);
      setNflMatches(matches);
      if (currentGame) {
        setNflDeadline(new Date(currentGame.deadline).toISOString().slice(0, 16));
      }
    } catch (err) {
      console.error('Failed to fetch current NFL game:', err);
      setCurrentNflGame(null);
      setNflMatches([]);
    }
  };

  const fetchCurrentNflscGame = async () => {
    try {
      const { currentGame } = await fetchCurrentNflscGameFixtures();
      setCurrentNflscGame(currentGame);
      if (currentGame) {
        setNflscDeadline(new Date(currentGame.deadline).toISOString().slice(0, 16));
      }
    } catch (err) {
      console.error('Failed to fetch current NFLSC game:', err);
      setCurrentNflscGame(null);
    }
  };

  const fetchTeams = async () => {
    try {
      const fetchedTeams = await getTeams();
      setTeams(fetchedTeams);
    } catch (err) {
      console.error('Failed to fetch teams:', err);
      setTeams([]);
    }
  };

  const handleCreateNewGame = async () => {
    if (!newGameName.trim()) {
      setError('Please enter a name for the new game');
      return;
    }
    try {
      await createNewGame(newGameName);
      fetchCurrentGame();
      setNewGameName('');
      setError(null);
      setSyncMessage('New football game created successfully!');
    } catch (err) {
      setError('Failed to create new football game');
    }
  };

  const handleCreateNewNflGame = async (e) => {
    e.preventDefault();
    if (!newNflGameName.trim()) {
      setError('Please enter a name for the new NFL game');
      return;
    }
    try {
      console.log('Creating new NFL game with name:', newNflGameName); // Add this log
      await createNewNflGame(newNflGameName);
      await fetchCurrentNflGame();
      setNewNflGameName('');
      setError(null);
      setSyncMessage('New NFL game created successfully!');
    } catch (err) {
      console.error('Error creating new NFL game:', err);
      setError('Failed to create new NFL game: ' + err.message);
    }
  };

  const handleCreateNewNflscGame = async (e) => {
    e.preventDefault();
    if (!newNflscGameName.trim()) {
      setError('Please enter a name for the new NFLSC game');
      return;
    }
    try {
      console.log('Creating new NFLSC game with name:', newNflscGameName);
      await createNewNflscGame(newNflscGameName);
      await fetchCurrentNflscGame();
      setNewNflscGameName('');
      setError(null);
      setSyncMessage('New NFLSC game created successfully!');
    } catch (err) {
      console.error('Error creating new NFLSC game:', err);
      setError('Failed to create new NFLSC game: ' + err.message);
    }
  };

  const handleUpdateDeadline = async (e) => {
    e.preventDefault();
    try {
      await updateGameDeadline(currentGame._id, new Date(deadline));
      await fetchCurrentGame();
      setError(null);
      setSyncMessage('Deadline updated successfully!');
    } catch (err) {
      setError('Failed to update deadline');
    }
  };

  const handleSyncFootballData = async () => {
    try {
      setSyncMessage('Syncing football data...');
      await syncFootballData();
      setSyncMessage('Football data synced successfully!');
      setError(null);
    } catch (err) {
      console.error('Error syncing football data:', err);
      setError('Failed to sync football data. Please try again.');
    }
  };

  const handleSyncNflData = async () => {
    try {
      setNflSyncMessage('Syncing NFL data...');
      const result = await syncNflData();
      console.log('NFL sync result:', result);
      setNflSyncMessage(`NFL data synced. ${result.message} ${result.details || ''}`);
      setError(null);
      await fetchCurrentNflGame();  // This will fetch the latest matches as well
    } catch (err) {
      console.error('Error syncing NFL data:', err);
      setError(`Failed to sync NFL data: ${err.message}`);
      setNflSyncMessage(null);
    }
  };

  const handleTeamChange = async (e) => {
    const teamId = e.target.value;
    setSelectedTeam(teamId);
    try {
      const fetchedPlayers = await getPlayers(teamId);
      setPlayers(fetchedPlayers);
      setScoringPlayers([]);
    } catch (err) {
      setError('Failed to fetch players');
    }
  };

  const handlePlayerToggle = (playerId) => {
    setScoringPlayers(prev => 
      prev.includes(playerId) ? prev.filter(id => id !== playerId) : [...prev, playerId]
    );
  };

  const handleUpdateScores = async () => {
    try {
      await updateScores(selectedTeam, scoringPlayers);
      setError(null);
      setSyncMessage('Scores updated successfully!');
      setScoringPlayers([]);
    } catch (err) {
      setError('Failed to update scores');
    }
  };

  const handleMarkMatchComplete = async () => {
    try {
      await markMatchComplete(selectedTeam);
      setError(null);
      setSyncMessage('Match marked as complete!');
    } catch (err) {
      setError('Failed to mark match as complete');
    }
  };

  const handleUpdateNflScore = async () => {
    try {
      console.log('Updating NFL match result...');
      await updateNflMatchResult(selectedNflMatch, parseInt(nflHomeScore), parseInt(nflAwayScore));
      setSyncMessage('NFL match result updated successfully!');
      
      if (currentNflscGame) {
        console.log(`Processing NFLSC results for gameweek: ${currentNflscGame.name}`);
        await processNflscResults(currentNflscGame.name);
        setSyncMessage(prevMessage => prevMessage + ' NFLSC results processed successfully!');
      } else {
        console.log('No current NFLSC game found, skipping result processing.');
      }
      
      await fetchCurrentNflGame();
      setNflHomeScore('');
      setNflAwayScore('');
      setError(null);
    } catch (err) {
      console.error('Error updating NFL match result or processing NFLSC results:', err);
      setError(`Failed to update NFL match result or process NFLSC results: ${err.message}`);
    }
  };

  if (loading) return <div>Loading...</div>;

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>Admin Dashboard</h1>
      {error && <p className={styles.error}>{error}</p>}
      {syncMessage && <p className={styles.success}>{syncMessage}</p>}
      {nflSyncMessage && <p className={styles.success}>{nflSyncMessage}</p>}

      <div className={styles.sectionGroup}>
        <h2 className={styles.sectionGroupTitle}>Football Administration</h2>

        <div className={styles.section}>
          <h3>Current Game</h3>
          {currentGame ? (
            <div>
              <p>Game Name: {currentGame.name}</p>
              <p>Game ID: {currentGame._id}</p>
              <p>Current Deadline: {new Date(currentGame.deadline).toLocaleString()}</p>
              <form onSubmit={handleUpdateDeadline} className={styles.form}>
                <input 
                  type="datetime-local" 
                  value={deadline} 
                  onChange={(e) => setDeadline(e.target.value)}
                  className={styles.input}
                />
                <button type="submit" className={styles.button}>Update Deadline</button>
              </form>
            </div>
          ) : (
            <p>No active game. Create a new game to get started.</p>
          )}
        </div>

        <div className={styles.section}>
          <h3>Create New Football Game</h3>
          <input
            type="text"
            value={newGameName}
            onChange={(e) => setNewGameName(e.target.value)}
            placeholder="Enter new football game name"
            className={styles.input}
          />
          <button onClick={handleCreateNewGame} className={styles.button}>Start New Football Game</button>
        </div>

        <div className={styles.section}>
          <h3>Data Management</h3>
          <button onClick={handleSyncFootballData} className={styles.button}>Sync Football Data from Google Sheets</button>
        </div>

        <div className={styles.section}>
          <h3>Update Scores</h3>
          <select value={selectedTeam} onChange={handleTeamChange} className={styles.input}>
            <option value="">Select a team</option>
            {teams.map(team => (
              <option key={team._id} value={team._id}>{team.name}</option>
            ))}
          </select>
          {players.length > 0 && (
            <div className={styles.playerList}>
              <h4>Select scoring players:</h4>
              {players.map(player => (
                <label key={player._id} className={styles.playerLabel}>
                  <input
                    type="checkbox"
                    checked={scoringPlayers.includes(player._id)}
                    onChange={() => handlePlayerToggle(player._id)}
                  />
                  {player.name}
                </label>
              ))}
              <button onClick={handleUpdateScores} className={styles.button}>Update Scores</button>
            </div>
          )}
        </div>

        <div className={styles.section}>
          <h3>Mark Match as Complete</h3>
          <button onClick={handleMarkMatchComplete} className={styles.button} disabled={!selectedTeam}>
            Mark Match Complete
          </button>
        </div>
      </div>

      <div className={styles.sectionGroup}>
  <h2 className={styles.sectionGroupTitle}>NFL Administration</h2>

  <div className={styles.section}>
    <h3>Current NFL Game</h3>
    {currentNflGame ? (
      <div>
        <p>Game Name: {currentNflGame.name}</p>
        <p>Game ID: {currentNflGame._id}</p>
        <p>Current Deadline: {new Date(currentNflGame.deadline).toLocaleString()}</p>
      </div>
    ) : (
      <p>No active NFL game.</p>
    )}
  </div>

  <div className={styles.section}>
    <h3>Create New NFL Game</h3>
    <form onSubmit={handleCreateNewNflGame}>
      <input
        type="text"
        value={newNflGameName}
        onChange={(e) => setNewNflGameName(e.target.value)}
        placeholder="Enter new NFL game name"
        className={styles.input}
      />
      <button type="submit" className={styles.button}>Create New NFL Game</button>
    </form>
  </div>

  <div className={styles.section}>
    <h3>Data Management</h3>
    <button onClick={handleSyncNflData} className={styles.button}>Sync NFL Data from Google Sheets</button>
  </div>

  <div className={styles.section}>
        <h3>Update NFL Match Result</h3>
        {currentNflGame && currentNflscGame ? (
          <>
            <p>Current NFL Gameweek: {currentNflGame.name}</p>
            <p>Current NFLSC Game: {currentNflscGame.name}</p>
            <select 
              value={selectedNflMatch} 
              onChange={(e) => setSelectedNflMatch(e.target.value)}
              className={styles.input}
            >
              <option value="">Select a match</option>
              {nflMatches.map(match => (
                <option key={match._id} value={match._id}>
                  {match.awayTeam} @ {match.homeTeam} ({new Date(match.kickoffTime).toLocaleString()})
                </option>
              ))}
            </select>
            {selectedNflMatch && (
              <div>
                <input
                  type="number"
                  value={nflHomeScore}
                  onChange={(e) => setNflHomeScore(e.target.value)}
                  placeholder="Home Score"
                  className={styles.input}
                />
                <input
                  type="number"
                  value={nflAwayScore}
                  onChange={(e) => setNflAwayScore(e.target.value)}
                  placeholder="Away Score"
                  className={styles.input}
                />
                <button onClick={handleUpdateNflScore} className={styles.button}>
                  Update NFL Score
                </button>
              </div>
            )}
          </>
        ) : (
          <p>No active NFL or NFLSC game. Create new games to update match results.</p>
        )}
      </div>
      </div>
      <div className={styles.sectionGroup}>
        <h2 className={styles.sectionGroupTitle}>NFL Supercontest Administration</h2>

        <div className={styles.section}>
          <h3>Current NFLSC Game</h3>
          {currentNflscGame ? (
            <div>
              <p>Game Name: {currentNflscGame.name}</p>
              <p>Game ID: {currentNflscGame._id}</p>
              <p>Current Deadline: {new Date(currentNflscGame.deadline).toLocaleString()}</p>
            </div>
          ) : (
            <p>No active NFLSC game.</p>
          )}
        </div>

        <div className={styles.section}>
          <h3>Create New NFLSC Game</h3>
          <form onSubmit={handleCreateNewNflscGame}>
            <input
              type="text"
              value={newNflscGameName}
              onChange={(e) => setNewNflscGameName(e.target.value)}
              placeholder="Enter new NFLSC game name"
              className={styles.input}
            />
            <button type="submit" className={styles.button}>Create New NFLSC Game</button>
          </form>
        </div>

        {/* You can add more NFLSC-specific sections here if needed */}
      </div>
    </div>
  );
}

export default Admin;