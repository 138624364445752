import React, { useState, useEffect } from 'react';
import { getCurrentNflGame, fetchNflMatches, submitNflSelection, checkEligibility } from '../../services/api';
import Card from '../common/Card';
import LoadingSpinner from '../common/LoadingSpinner';
import styles from './MakeSelections.module.css';

function MakeSelections() {
  const [currentGame, setCurrentGame] = useState(null);
  const [matches, setMatches] = useState([]);
  const [selectedMatch, setSelectedMatch] = useState(null);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [isEligible, setIsEligible] = useState(true);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    try {
      setIsLoading(true);
      const [gameData, matchesData, eligibilityData] = await Promise.all([
        getCurrentNflGame(),
        fetchNflMatches(),
        checkEligibility()
      ]);
      setCurrentGame(gameData);
      setMatches(matchesData);
      setIsEligible(eligibilityData.isEligible);
    } catch (err) {
      console.error('Error loading data:', err);
      setError('Failed to load game data. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setSuccessMessage(null);

    if (!selectedMatch || !selectedTeam) {
      setError('Please select a match and a team before submitting.');
      return;
    }

    try {
      await submitNflSelection(selectedMatch, selectedTeam);
      setSuccessMessage('Selection submitted successfully!');
      setSelectedMatch(null);
      setSelectedTeam(null);
    } catch (error) {
      console.error('Error submitting selection:', error);
      setError(error.message || 'Failed to submit selection. Please try again.');
    }
  };

  if (isLoading) return <LoadingSpinner />;

  const isDeadlinePassed = currentGame && new Date() > new Date(currentGame.deadline);

  if (!isEligible) {
    return (
      <Card>
        <div className={styles.message}>
          <p>You have been eliminated from the competition and cannot make further selections.</p>
        </div>
      </Card>
    );
  }

  return (
    <Card>
      {successMessage && <p className={styles.success}>{successMessage}</p>}
      {error && <p className={styles.error}>{error}</p>}

      {currentGame && (
        <div className={styles.currentGame}>
          <h2 className={styles.sectionTitle}>Current Game: {currentGame.name}</h2>
          <p className={styles.deadlineInfo}>Deadline: {new Date(currentGame.deadline).toLocaleString()}</p>
        </div>
      )}

      {!currentGame && <p>No active game found. Please check back later.</p>}

      {currentGame && isDeadlinePassed && (
        <p className={styles.deadlineMessage}>The deadline for {currentGame.name} has passed. Selections can no longer be submitted.</p>
      )}

      {currentGame && !isDeadlinePassed && (
        <form onSubmit={handleSubmit} className={styles.form}>
          <table className={styles.matchesTable}>
            <thead>
              <tr>
                <th>Away Team</th>
                <th>Home Team</th>
                <th>Kickoff Time</th>
              </tr>
            </thead>
            <tbody>
              {matches.map(match => (
                <tr key={match._id}>
                  <td>
                    <label className={styles.teamLabel}>
                      <input
                        type="radio"
                        name="selection"
                        value={`${match._id}-away`}
                        checked={selectedMatch === match._id && selectedTeam === match.awayTeam}
                        onChange={() => {
                          setSelectedMatch(match._id);
                          setSelectedTeam(match.awayTeam);
                        }}
                      />
                      <span>{match.awayTeam} ({match.awayHandicap})</span>
                    </label>
                  </td>
                  <td>
                    <label className={styles.teamLabel}>
                      <input
                        type="radio"
                        name="selection"
                        value={`${match._id}-home`}
                        checked={selectedMatch === match._id && selectedTeam === match.homeTeam}
                        onChange={() => {
                          setSelectedMatch(match._id);
                          setSelectedTeam(match.homeTeam);
                        }}
                      />
                      <span>{match.homeTeam} ({match.homeHandicap})</span>
                    </label>
                  </td>
                  <td>{new Date(match.kickoffTime).toLocaleString()}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <button type="submit" className={styles.submitButton} disabled={!selectedMatch || !selectedTeam}>
            Submit Selection
          </button>
        </form>
      )}
    </Card>
  );
}

export default MakeSelections;