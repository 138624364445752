import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment-timezone';
import { fetchCurrentNflGameFixtures, fetchCurrentNflscGameFixtures } from '../../services/api';
import Card from '../../components/common/Card';
import styles from './AmericanFootballHome.module.css';
import { FEATURE_FLAGS } from '../../featureFlags';

const formatDateTime = (dateTimeString) => {
  const date = moment.utc(dateTimeString);
  const ukTime = date.tz('Europe/London');
  return ukTime.format('dddd, h:mm a z');
};

const AmericanFootballHome = () => {
  const [lmsGame, setLmsGame] = useState(null);
  const [lmsFixtures, setLmsFixtures] = useState([]);
  const [scGame, setScGame] = useState(null);
  const [scFixtures, setScFixtures] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const loadData = async () => {
      try {
        setLoading(true);
        const [lmsData, scData] = await Promise.all([
          FEATURE_FLAGS.SHOW_AMERICAN_FOOTBALL ? fetchCurrentNflGameFixtures() : Promise.resolve({}),
          FEATURE_FLAGS.SHOW_NFLSC ? fetchCurrentNflscGameFixtures() : Promise.resolve({})
        ]);
        if (FEATURE_FLAGS.SHOW_AMERICAN_FOOTBALL) {
          setLmsGame(lmsData.currentGame);
          setLmsFixtures(lmsData.matches);
        }
        if (FEATURE_FLAGS.SHOW_NFLSC) {
          setScGame(scData.currentGame);
          setScFixtures(scData.matches);
        }
        setLoading(false);
      } catch (err) {
        console.error('Error loading data:', err);
        setError('Failed to load data. Please try again later.');
        setLoading(false);
      }
    };

    loadData();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className={styles.home}>
      <h1 className={styles.title}>American Football</h1>

      {FEATURE_FLAGS.SHOW_AMERICAN_FOOTBALL && lmsGame && (
        <Card>
          <h2 className={styles.gameweekTitle}>Last Man Standing (LMS): {lmsGame.name}</h2>
          <p className={styles.deadline}>Deadline: {formatDateTime(lmsGame.deadline)}</p>
          
          <h3 className={styles.fixturesTitle}>Fixtures:</h3>
          <table className={styles.fixturesTable}>
            <thead>
              <tr>
                <th>Home Team</th>
                <th>Away Team</th>
                <th>Kick-off Time</th>
              </tr>
            </thead>
            <tbody>
              {lmsFixtures.map((match, index) => (
                <tr key={index}>
                  <td>{match.homeTeam}</td>
                  <td>{match.awayTeam}</td>
                  <td>{formatDateTime(match.kickoffTime)}</td>
                </tr>
              ))}
            </tbody>
          </table>

          <div className={styles.links}>
            <Link to="/american-football/lms/make-selections" className={styles.button}>Make Selections</Link>
            <Link to="/american-football/lms/my-selections" className={styles.button}>My Selections</Link>
            <Link to="/american-football/lms/all-selections" className={styles.button}>View All Selections</Link>
          </div>
        </Card>
      )}

      {FEATURE_FLAGS.SHOW_NFLSC && scGame && (
        <Card>
          <h2 className={styles.gameweekTitle}>Super Contest: {scGame.name}</h2>
          <p className={styles.deadline}>Deadline: {formatDateTime(scGame.deadline)}</p>
          
          <h3 className={styles.fixturesTitle}>Fixtures:</h3>
          <table className={styles.fixturesTable}>
            <thead>
              <tr>
                <th>Home Team</th>
                <th>Away Team</th>
                <th>Kick-off Time</th>
              </tr>
            </thead>
            <tbody>
              {scFixtures.map((match, index) => (
                <tr key={index}>
                  <td>{match.homeTeam}</td>
                  <td>{match.awayTeam}</td>
                  <td>{formatDateTime(match.kickoffTime)}</td>
                </tr>
              ))}
            </tbody>
          </table>

          <div className={styles.links}>
            <Link to="/american-football/sc/make-selections" className={styles.button}>Make Selections</Link>
            <Link to="/american-football/sc/my-selections" className={styles.button}>My Selections</Link>
            <Link to="/american-football/sc/weekly-selections" className={styles.button}>Weekly Selections</Link>
            <Link to="/american-football/sc/leaderboard" className={styles.button}>Leaderboard</Link>
          </div>
        </Card>
      )}
    </div>
  );
};

export default AmericanFootballHome;