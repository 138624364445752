import React, { useState, useEffect } from 'react';
import { 
  fetchCurrentNflscGameFixtures, 
  submitNflscSelections, 
  fetchSelections 
} from '../../services/api';
import Card from '../common/Card';
import LoadingSpinner from '../common/LoadingSpinner';
import styles from './MakeSelections.module.css';
import commonStyles from './commonStyles.module.css';
import { formatHandicap } from '../../utils/formatHandicap';

function MakeSelections() {
  const [currentGame, setCurrentGame] = useState(null);
  const [matches, setMatches] = useState([]);
  const [selections, setSelections] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [submittedSelections, setSubmittedSelections] = useState(null);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    try {
      setIsLoading(true);
      const { currentGame, matches } = await fetchCurrentNflscGameFixtures();
      setCurrentGame(currentGame);
      setMatches(matches);
  
      if (currentGame) {
        const selectionsData = await fetchSelections();
        const currentSelections = selectionsData.find(s => s.gameweek === currentGame.name);
        if (currentSelections) {
          setHasSubmitted(true);
          setSubmittedSelections(currentSelections.selections);
        } else {
          setHasSubmitted(false);
          setSubmittedSelections(null);
        }
      }
    } catch (err) {
      console.error('Error loading data:', err);
      setError('Failed to load game data. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleSelectionChange = (matchId, team, handicap) => {
    setSelections(prev => {
      // Check if this team is already selected
      const existingSelection = prev.find(s => s.matchId === matchId);
      
      if (existingSelection && existingSelection.selectedTeam === team) {
        // If the same team is clicked again, remove it (deselect)
        return prev.filter(s => s.matchId !== matchId);
      } else {
        // Remove any existing selection for this match
        const withoutCurrentMatch = prev.filter(s => s.matchId !== matchId);

        if (prev.length >= 5 && !existingSelection) {
            alert("You can only select 5 teams. Please deselect a team before making a new selection.");
            return prev; // Return previous state without changes
          }
        
        // Add the new selection
        return [...withoutCurrentMatch, { matchId, selectedTeam: team, handicap }];
      }
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setSuccessMessage(null);
  
    if (selections.length !== 5) {
      setError('Please make exactly 5 selections before submitting.');
      return;
    }
  
    try {
      const result = await submitNflscSelections(currentGame.name, selections);
      setSuccessMessage('Selections submitted successfully!');
      setSubmittedSelections(result.selections);
      setSelections([]);
      setHasSubmitted(true);
    } catch (error) {
      console.error('Error submitting selections:', error);
      setError(error.message || 'Failed to submit selections. Please try again.');
    }
  };

  if (isLoading) return <LoadingSpinner />;

  const isDeadlinePassed = currentGame && new Date() > new Date(currentGame.deadline);

  return (
    <Card>
      <div className={styles.pageContainer}>
        <h1 className={commonStyles.pageTitle}>Make Selections</h1>
        {successMessage && <p className={commonStyles.success}>{successMessage}</p>}
        {error && <p className={commonStyles.error}>{error}</p>}
    
        {currentGame && (
          <div className={commonStyles.currentGame}>
            <h2 className={commonStyles.sectionTitle}>Current Game: {currentGame.name}</h2>
            <p className={commonStyles.deadlineInfo}>Deadline: {new Date(currentGame.deadline).toLocaleString()}</p>
          </div>
        )}
    
        {!currentGame && <p className={commonStyles.message}>No active game found. Please check back later.</p>}
    
        {currentGame && isDeadlinePassed && (
          <p className={commonStyles.message}>The deadline for {currentGame.name} has passed. Selections can no longer be submitted.</p>
        )}
    
        {currentGame && !isDeadlinePassed && hasSubmitted && (
          <div className={styles.submittedSelections}>
            <h3 className={styles.sectionSubtitle}>Your Submitted Selections:</h3>
            {submittedSelections.map((selection, index) => (
              <div key={index} className={styles.selectionItem}>
                <p><strong>Selected Team:</strong> {selection.selectedTeam} (Handicap: {selection.handicap})</p>
                <p><strong>Match:</strong> {selection.match.awayTeam} @ {selection.match.homeTeam}</p>
              </div>
            ))}
          </div>
        )}
    
        {currentGame && !isDeadlinePassed && !hasSubmitted && (
          <form onSubmit={handleSubmit} className={styles.form}>
  <div className={styles.selectionCounter}>
    Selections made: {selections.length} / 5
  </div>
  <div className={styles.matchList}>
    {matches.map(match => (
      <div key={match._id} className={styles.matchItem}>
        <div className={styles.teamButtons}>
          <button 
            type="button"
            onClick={() => handleSelectionChange(match._id, match.awayTeam, match.awayHandicap)}
            className={`${styles.teamButton} ${selections.some(s => s.matchId === match._id && s.selectedTeam === match.awayTeam) ? styles.selected : ''}`}
          >
            {match.awayTeam} ({formatHandicap(match.awayHandicap)})
          </button>
          <span className={styles.vsLabel}>@</span>
          <button 
            type="button"
            onClick={() => handleSelectionChange(match._id, match.homeTeam, match.homeHandicap)}
            className={`${styles.teamButton} ${selections.some(s => s.matchId === match._id && s.selectedTeam === match.homeTeam) ? styles.selected : ''}`}
          >
            {match.homeTeam} ({formatHandicap(match.homeHandicap)})
          </button>
        </div>
      </div>
    ))}
  </div>
  <button type="submit" className={styles.submitButton} disabled={selections.length !== 5}>
    Submit Selections
  </button>
</form>
        )}
      </div>
    </Card>
  );
}

export default MakeSelections;