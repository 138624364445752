import React, { useState, useEffect } from 'react';
import { fetchUserNflSelections, updateUserNflSelection, getCurrentNflGame, fetchNflMatches } from '../../services/api';
import Card from '../common/Card';
import LoadingSpinner from '../common/LoadingSpinner';
import styles from './MySelections.module.css';

function MySelections() {
  const [selections, setSelections] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [currentGame, setCurrentGame] = useState(null);
  const [editingSelection, setEditingSelection] = useState(null);
  const [matches, setMatches] = useState([]);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    try {
      setIsLoading(true);
      const [selectionsData, gameData, matchesData] = await Promise.all([
        fetchUserNflSelections(),
        getCurrentNflGame(),
        fetchNflMatches()
      ]);
      console.log('Selections:', selectionsData);
      console.log('Current Game:', gameData);
      console.log('Matches:', matchesData);
      setSelections(selectionsData);
      setCurrentGame(gameData);
      setMatches(matchesData);
    } catch (err) {
      console.error('Error loading data:', err);
      setError('Failed to load data. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleEdit = (selection) => {
    console.log('Editing selection:', selection);
    setEditingSelection(selection);
  };

  const handleCancelEdit = () => {
    setEditingSelection(null);
  };

  const handleSaveEdit = async (selectionId, newMatchId, newTeam) => {
    setError(null);
    setSuccessMessage(null);

    if (isDeadlinePassed) {
      setError('Deadline has passed. Selections can no longer be edited.');
      return;
    }

    try {
      await updateUserNflSelection(selectionId, newMatchId, newTeam);
      setSuccessMessage('Selection updated successfully!');
      await loadData();  // Reload all data
      setEditingSelection(null);
    } catch (err) {
      console.error('Error updating selection:', err);
      setError('Failed to update selection. Please try again.');
    }
  };

  if (isLoading) return <LoadingSpinner />;

  const isDeadlinePassed = currentGame && new Date() > new Date(currentGame.deadline);

  const currentSelections = selections.filter(s => s.gameweek === currentGame?.name);
  const archivedSelections = selections.filter(s => s.gameweek !== currentGame?.name);

  return (
    <Card>
      {successMessage && <p className={styles.success}>{successMessage}</p>}
      {error && <p className={styles.error}>{error}</p>}
      
      {currentGame && (
        <div className={styles.currentGame}>
          <h2 className={styles.sectionTitle}>Current Game: {currentGame.name}</h2>
          <p className={styles.deadlineInfo}>Deadline: {new Date(currentGame.deadline).toLocaleString()}</p>
        </div>
      )}
      
      {currentSelections.length > 0 ? (
        <div className={styles.selectionGroup}>
          <h3>Current Selection</h3>
          {currentSelections.map(selection => (
            <div key={selection._id} className={styles.selectionItem}>
              {editingSelection && editingSelection._id === selection._id ? (
                <EditSelectionForm
                  selection={selection}
                  matches={matches}
                  onSave={handleSaveEdit}
                  onCancel={handleCancelEdit}
                />
              ) : (
                <>
                  <p>Selected Team: {selection.selectedTeam} (Handicap: {selection.handicap})</p>
                  <p>Match: {selection.match ? `${selection.match.awayTeam} @ ${selection.match.homeTeam}` : 'N/A'}</p>
                  <p>Status: {selection.isCorrect === null ? 'Pending' : (selection.isCorrect ? 'Correct' : 'Incorrect')}</p>
                  {!isDeadlinePassed && (
                    <button onClick={() => handleEdit(selection)} className={styles.editButton}>
                      Edit Selection
                    </button>
                  )}
                </>
              )}
            </div>
          ))}
        </div>
      ) : (
        <div className={styles.noCurrentSelections}>
          <p className={styles.warningMessage}>
            You haven't made a selection for this gameweek yet. 
            {!isDeadlinePassed ? " Don't forget to submit your selection before the deadline!" : " The deadline has passed for this gameweek."}
          </p>
        </div>
      )}
      
      {archivedSelections.length > 0 && (
        <div className={styles.selectionGroup}>
          <h3>Archived Selections</h3>
          {archivedSelections.map(selection => (
            <div key={selection._id} className={styles.selectionItem}>
              <p>Game: {selection.gameweek}</p>
              <p>Selected Team: {selection.selectedTeam} (Handicap: {selection.handicap})</p>
              <p>Match: {selection.match ? `${selection.match.homeTeam} vs ${selection.match.awayTeam}` : 'N/A'}</p>
              <p>Result: {selection.isCorrect === null ? 'Pending' : (selection.isCorrect ? 'Correct' : 'Incorrect')}</p>
            </div>
          ))}
        </div>
      )}

      {selections.length === 0 && <p className={styles.noSelections}>No selections found.</p>}
    </Card>
  );
}

function EditSelectionForm({ selection, matches, onSave, onCancel }) {
  const [selectedMatch, setSelectedMatch] = useState(selection.match?._id || '');
  const [selectedTeam, setSelectedTeam] = useState(selection.selectedTeam || '');

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave(selection._id, selectedMatch, selectedTeam);
  };
  
  return (
    <form onSubmit={handleSubmit}>
      <table className={styles.matchesTable}>
        <thead>
          <tr>
            <th>Away Team</th>
            <th>Home Team</th>
            <th>Kickoff Time</th>
          </tr>
        </thead>
        <tbody>
          {matches.map(match => (
            <tr key={match._id}>
              <td>
                <label className={styles.teamLabel}>
                  <input
                    type="radio"
                    name="selection"
                    value={`${match._id}-away`}
                    checked={selectedMatch === match._id && selectedTeam === match.awayTeam}
                    onChange={() => {
                      setSelectedMatch(match._id);
                      setSelectedTeam(match.awayTeam);
                    }}
                  />
                  <span>{match.awayTeam} ({match.awayHandicap})</span>
                </label>
              </td>
              <td>
                <label className={styles.teamLabel}>
                  <input
                    type="radio"
                    name="selection"
                    value={`${match._id}-home`}
                    checked={selectedMatch === match._id && selectedTeam === match.homeTeam}
                    onChange={() => {
                      setSelectedMatch(match._id);
                      setSelectedTeam(match.homeTeam);
                    }}
                  />
                  <span>{match.homeTeam} ({match.homeHandicap})</span>
                </label>
              </td>
              <td>{new Date(match.kickoffTime).toLocaleString()}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <button type="submit">Save</button>
      <button type="button" onClick={onCancel}>Cancel</button>
    </form>
  );
}

export default MySelections;