import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../../AuthContext';
import styles from './MainNavigation.module.css';

const MainNavigation = ({ games }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { user, logout } = useAuth();

  const handleLogout = () => {
    logout();
    navigate('/');
    setIsMenuOpen(false);
  };

  return (
    <nav className={styles.mainNav}>
      <div className={styles.navLeft}>
        <Link to="/" className={styles.logo}>Predictions</Link>
      </div>
      <div className={styles.navRight}>
        <button className={styles.menuButton} onClick={() => setIsMenuOpen(!isMenuOpen)}>
          Menu
        </button>
        <div className={`${styles.menuDropdown} ${isMenuOpen ? styles.open : ''}`}>
          {games.map(game => (
            <Link 
              key={game.path} 
              to={game.path} 
              className={`${styles.menuItem} ${location.pathname.startsWith(game.path) ? styles.active : ''}`}
              onClick={() => setIsMenuOpen(false)}
            >
              {game.name}
            </Link>
          ))}
          {user ? (
              <>
              <Link to="/profile" className={styles.menuItem} onClick={() => setIsMenuOpen(false)}>Profile</Link>
              {user.isAdmin && <Link to="/admin" className={styles.menuItem} onClick={() => setIsMenuOpen(false)}>Admin</Link>}
              <button onClick={handleLogout} className={`${styles.menuItem} ${styles.logoutButton}`}>Logout</button>
            </>
          ) : (
            <>
              <Link to="/login" className={styles.menuItem} onClick={() => setIsMenuOpen(false)}>Login</Link>
              <Link to="/register" className={styles.menuItem} onClick={() => setIsMenuOpen(false)}>Register</Link>
            </>
          )}
        </div>
      </div>
    </nav>
  );
};

export default MainNavigation;