import React from 'react';
import { Route, Navigate } from 'react-router-dom';
import { useAuth } from '../../AuthContext';

const AdminRoute = ({ children }) => {
  const { user, loading } = useAuth();
  console.log('AdminRoute: User:', user, 'Loading:', loading);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!user || !user.isAdmin) {
    console.log('AdminRoute: User is not admin, redirecting');
    return <Navigate to="/login" replace />;
  }

  console.log('AdminRoute: Rendering admin content');
  return children;
};

export default AdminRoute;