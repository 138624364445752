import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment-timezone';
import { fetchCurrentGameFixtures } from '../services/api';
import Card from '../components/common/Card';
import styles from './Home.module.css';

// Updated helper function to format date and time
const formatDateTime = (dateTimeString) => {
  // Parse the UTC time from the database
  const date = moment.utc(dateTimeString);
  
  // Convert to UK time
  const ukTime = date.tz('Europe/London');
  
  // Format the date and time
  return ukTime.format('dddd, h:mm a z'); // e.g., "Saturday, 12:30 pm BST"
};

const Home = () => {
  const [currentGame, setCurrentGame] = useState(null);
  const [fixtures, setFixtures] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const loadData = async () => {
      try {
        const data = await fetchCurrentGameFixtures();
        setCurrentGame(data.currentGame);
        setFixtures(data.matches);
        setLoading(false);
      } catch (err) {
        console.error('Error loading data:', err);
        setError('Failed to load data. Please try again later.');
        setLoading(false);
      }
    };

    loadData();
  }, []);

  const loadData = async () => {
    try {
      const { currentGame, matches } = await fetchCurrentGameFixtures();
      if (currentGame) {
        setCurrentGame(currentGame);
        setFixtures(matches);
      } else {
        setCurrentGame(null);
        setFixtures([]);
      }
    } catch (error) {
      console.error('Error loading data:', error);
      setError('Failed to load game data. Please try again later.');
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className={styles.home}>
      <h1 className={styles.title}>Welcome to 5 Scorers</h1>
      <p className={styles.subtitle}>Pick your five players to score in the upcoming gameweek</p>

      {currentGame && (
        <Card>
          <h2 className={styles.gameweekTitle}>Current Game: {currentGame.name}</h2>
          <p className={styles.deadline}>Deadline: {formatDateTime(currentGame.deadline)}</p>
          
          <h3 className={styles.fixturesTitle}>Fixtures:</h3>
          <table className={styles.fixturesTable}>
            <thead>
              <tr>
                <th>Home Team</th>
                <th>Away Team</th>
                <th>Kick-off Time</th>
              </tr>
            </thead>
            <tbody>
              {fixtures.map((match, index) => (
                <tr key={index}>
                  <td>{match.homeTeam}</td>
                  <td>{match.awayTeam}</td>
                  <td>{formatDateTime(match.kickoffTime)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </Card>
      )}

      <div className={styles.links}>
        <Link to="/make-selections" className={styles.button}>Make Selections</Link>
        <Link to="/my-selections" className={styles.button}>My Selections</Link>
        <Link to="/all-selections" className={styles.button}>View All Selections</Link>
      </div>
    </div>
  );
};

export default Home;