import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AuthProvider, useAuth } from './AuthContext';
import MainNavigation from './components/navigation/MainNavigation';
import Homepage from './pages/Homepage';
import FootballRoutes from './routes/FootballRoutes';
import AmericanFootballLmsRoutes from './routes/AmericanFootballLmsRoutes';
import NflscRoutes from './routes/NflscRoutes';
import Login from './pages/Login';
import Register from './pages/Register';
import Admin from './pages/Admin';
import Profile from './pages/Profile';
import ForgotPassword from './components/ForgotPassword';
import ResetPassword from './components/ResetPassword';
import ProtectedRoute from './components/common/ProtectedRoute';
import AdminRoute from './components/common/AdminRoute';
import { FEATURE_FLAGS } from './featureFlags';
import './global.css';

function App() {
  const { user } = useAuth();
  console.log('Feature Flags:', FEATURE_FLAGS);

  const games = [
    ...(FEATURE_FLAGS.SHOW_FOOTBALL ? [{ 
      name: 'Football', 
      path: '/football',
    }] : []),
    ...(FEATURE_FLAGS.SHOW_AMERICAN_FOOTBALL ? [{ 
      name: 'American Football LMS', 
      path: '/american-football/lms',
    }] : []),
    ...(FEATURE_FLAGS.SHOW_NFLSC ? [{ 
      name: 'American Football SC', 
      path: '/american-football/sc',
    }] : []),
  ];

  return (
    <AuthProvider>
      <Router>
        <div className="app">
          <MainNavigation user={user} games={games} />
          <main className="main">
            <Routes>
              <Route path="/" element={<Homepage />} />
              {FEATURE_FLAGS.SHOW_FOOTBALL && (
                <Route path="/football/*" element={<FootballRoutes />} />
              )}
              {FEATURE_FLAGS.SHOW_AMERICAN_FOOTBALL && (
                <Route path="/american-football/lms/*" element={<AmericanFootballLmsRoutes />} />
              )}
              {FEATURE_FLAGS.SHOW_NFLSC && (
                <Route path="/american-football/sc/*" element={<NflscRoutes />} />
              )}
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route path="/admin" element={<AdminRoute><Admin /></AdminRoute>} />
              <Route path="/profile" element={<ProtectedRoute><Profile /></ProtectedRoute>} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/reset-password/:token" element={<ResetPassword />} />
              <Route path="*" element={<div>404: Page not found</div>} />
            </Routes>
          </main>
        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;