import React from 'react';
import { NavLink, Outlet } from 'react-router-dom';
import styles from './FootballLayout.module.css';

function FootballLayout() {
  return (
    <div className={styles.layout}>
      <nav className={styles.nav}>
        <span className={styles.gameIndicator}>Football</span>
        <NavLink to="/football/make-selections" className={({isActive}) => isActive ? styles.activeLink : styles.link}>
          Make Selections
        </NavLink>
        <NavLink to="/football/my-selections" className={({isActive}) => isActive ? styles.activeLink : styles.link}>
          My Selections
        </NavLink>
        <NavLink to="/football/all-selections" className={({isActive}) => isActive ? styles.activeLink : styles.link}>
          All Selections
        </NavLink>
      </nav>
      <div className={styles.content}>
        <Outlet />
      </div>
    </div>
  );
}

export default FootballLayout;