import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';
import { fetchAllNflSelections, getCurrentNflGame } from '../../services/api';
import Card from '../common/Card';
import LoadingSpinner from '../common/LoadingSpinner';
import styles from './AllSelections.module.css';

function AllSelections() {
  const [selections, setSelections] = useState([]);
  const [currentGame, setCurrentGame] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);

  const formatKickoffTime = (dateString) => {
    const date = moment(dateString).tz('America/New_York');
    return date.format('dddd, MMMM D, YYYY h:mm A z');
  };

  useEffect(() => {
    const loadData = async () => {
      try {
        const [selectionsData, gameData] = await Promise.all([
          fetchAllNflSelections(),
          getCurrentNflGame()
        ]);
        setCurrentGame(gameData);

        if (typeof selectionsData === 'object' && selectionsData.message) {
          setMessage(selectionsData.message);
        } else {
          setSelections(selectionsData || []);
        }
        setIsLoading(false);
      } catch (err) {
        console.error('Error loading data:', err);
        setError('An error occurred while fetching data. Please try again later.');
        setIsLoading(false);
      }
    };

    loadData();
  }, []);

  const getStatusClass = (status) => {
    switch(status) {
      case 'Win':
        return styles.statusWin;
      case 'Loss':
        return styles.statusLoss;
      default:
        return styles.statusPending;
    }
  };

  if (isLoading) return <LoadingSpinner />;
  if (error) return <Card><div className={styles.error}>{error}</div></Card>;

  const isDeadlinePassed = currentGame && new Date(currentGame.deadline) < new Date();

  return (
    <Card>
      {currentGame && (
        <div className={styles.currentGame}>
          <h2 className={styles.sectionTitle}>Current Game: {currentGame.name}</h2>
          <p className={styles.deadlineInfo}>
            Deadline: {formatKickoffTime(currentGame.deadline)}
          </p>
        </div>
      )}
      
      {message ? (
        <div className={styles.message}>
          <p>{message}</p>
        </div>
      ) : !isDeadlinePassed ? (
        <div className={styles.message}>
          <p>Selections will be visible after the deadline has passed.</p>
        </div>
      ) : selections.length === 0 ? (
        <div className={styles.message}>
          <p>No selections have been made for this gameweek.</p>
        </div>
      ) : (
        <div className={styles.tableContainer}>
          <table className={styles.table}>
            <thead className={styles.tableHeader}>
              <tr>
                <th>User</th>
                <th>Selected Team</th>
                <th>Handicap</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {selections.map((selection, index) => (
                <tr key={selection._id || index} className={`${styles.selectionRow} ${getStatusClass(selection.result)}`}>
                  <td className={styles.userName}>
                    {selection.user ? (selection.user.displayName || 'Unknown User') : 'Unknown User'}
                  </td>
                  <td>{selection.selectedTeam}</td>
                  <td>{selection.handicap}</td>
                  <td>{selection.result !== 'Pending' ? selection.result : 'Pending'}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </Card>
  );
}

export default AllSelections;