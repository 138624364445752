import React, { useState, useEffect } from 'react';
import styles from './Leaderboard.module.css';

const Leaderboard = () => {
  const [cumulativeLeaderboard, setCumulativeLeaderboard] = useState([]);
  const [gameweekBreakdown, setGameweekBreakdown] = useState([]);
  const [gameweeks, setGameweeks] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchLeaderboardData = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await fetch('/api/nflsc/leaderboard-full', {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
        
        if (!response.ok) {
          const textResponse = await response.text();
          throw new Error(`HTTP error! status: ${response.status}, body: ${textResponse}`);
        }
        
        const data = await response.json();
        setCumulativeLeaderboard(data.cumulativeLeaderboard);
        setGameweekBreakdown(data.gameweekBreakdown);
        setGameweeks(data.gameweeks);
      } catch (error) {
        console.error('Error fetching leaderboard data:', error);
        setError(`Failed to fetch leaderboard data: ${error.message}`);
      }
    };

    fetchLeaderboardData();
  }, []);

  if (error) {
    return <div className={styles.error}>{error}</div>;
  }

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>Leaderboard</h1>
      
      <h2 className={styles.sectionTitle}>Cumulative Scores</h2>
      <table className={styles.leaderboardTable}>
        <thead>
          <tr>
            <th>Rank</th>
            <th>User</th>
            <th>Total Score</th>
          </tr>
        </thead>
        <tbody>
          {cumulativeLeaderboard.map((entry, index) => (
            <tr key={index}>
              <td className={styles.rank}>{index + 1}</td>
              <td>{entry.displayName}</td>
              <td className={styles.score}>{entry.totalScore}</td>
            </tr>
          ))}
        </tbody>
      </table>

      <h2 className={styles.sectionTitle}>Gameweek Breakdown</h2>
      <div className={styles.tableWrapper}>
        <table className={styles.breakdownTable}>
          <thead>
            <tr>
              <th>User</th>
              {gameweeks.map(gameweek => (
                <th key={gameweek}>{gameweek}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {gameweekBreakdown.map((user, index) => (
              <tr key={index}>
                <td>{user.displayName}</td>
                {gameweeks.map(gameweek => (
                  <td key={gameweek} className={styles.score}>
                    {user.scores[gameweek] || '-'}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Leaderboard;