import React, { useState, useEffect } from 'react';
import { fetchSelections, fetchCurrentNflscGameFixtures, updateNflscSelections } from '../../services/api';
import Card from '../common/Card';
import LoadingSpinner from '../common/LoadingSpinner';
import styles from './MySelections.module.css';
import commonStyles from './commonStyles.module.css';

function MySelections() {
  const [selections, setSelections] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [currentGame, setCurrentGame] = useState(null);
  const [editingSelection, setEditingSelection] = useState(null);
  const [matches, setMatches] = useState([]);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    try {
      setIsLoading(true);
      const { currentGame, matches } = await fetchCurrentNflscGameFixtures();
      setCurrentGame(currentGame);
      setMatches(matches);
  
      if (currentGame) {
        const selectionsData = await fetchSelections();
        console.log('Selections:', selectionsData);
        console.log('Current Game:', currentGame);
        console.log('Matches:', matches);
        setSelections(selectionsData);
      }
    } catch (err) {
      console.error('Error loading data:', err);
      setError('Failed to load data. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleEdit = (selection) => {
    console.log('Editing selection:', selection);
    setEditingSelection(selection);
  };

  const handleCancelEdit = () => {
    setEditingSelection(null);
  };

  const handleSaveEdit = async (newSelections) => {
    setError(null);
    setSuccessMessage(null);
  
    if (isDeadlinePassed) {
      setError('Deadline has passed. Selections can no longer be edited.');
      return;
    }
  
    if (newSelections.length !== 5) {
      setError('Please make exactly 5 selections.');
      return;
    }
  
    try {
      console.log('Attempting to save selections:', newSelections);
      await updateNflscSelections(currentGame.name, newSelections);
      setSuccessMessage('Selections updated successfully!');
      await loadData();  // Reload all data
      setEditingSelection(null);
    } catch (err) {
      console.error('Error updating selections:', err);
      setError(`Failed to update selections: ${err.message}`);
    }
  };

  if (isLoading) return <LoadingSpinner />;

  const isDeadlinePassed = currentGame && new Date() > new Date(currentGame.deadline);

  const currentSelections = selections.find(s => s.gameweek === currentGame?.name);
  const archivedSelections = selections.filter(s => s.gameweek !== currentGame?.name);

  return (
    <Card>
      <div className={commonStyles.pageContainer}>
        <h1 className={commonStyles.pageTitle}>My Selections</h1>
        {successMessage && <p className={commonStyles.success}>{successMessage}</p>}
        {error && <p className={commonStyles.error}>{error}</p>}
        
        {currentGame && (
          <div className={commonStyles.currentGame}>
            <h2 className={commonStyles.sectionTitle}>Current Game: {currentGame.name}</h2>
            <p className={commonStyles.deadlineInfo}>Deadline: {new Date(currentGame.deadline).toLocaleString()}</p>
          </div>
        )}
        
        {currentSelections ? (
          <div className={styles.selectionGroup}>
            <h3 className={styles.groupTitle}>Current Selections</h3>
            {editingSelection ? (
              <EditSelectionsForm
                selections={currentSelections.selections}
                matches={matches}
                onSave={handleSaveEdit}
                onCancel={handleCancelEdit}
              />
            ) : (
              <>
                {currentSelections.selections.map((selection, index) => (
                  <div key={index} className={styles.selectionItem}>
                    <div className={styles.selectionDetails}>
                      <span className={styles.teamInfo}>
                        <strong>Selected Team:</strong> {selection.selectedTeam} (Handicap: {selection.handicap})
                      </span>
                      <span className={styles.statusInfo}>
                        <strong>Status:</strong> {selection.result || 'Pending'}
                      </span>
                    </div>
                    <div className={styles.matchInfo}>
                      <strong>Match:</strong> {selection.match ? `${selection.match.awayTeam} @ ${selection.match.homeTeam}` : 'N/A'}
                    </div>
                  </div>
                ))}
                {!isDeadlinePassed && (
                  <button onClick={() => handleEdit(currentSelections)} className={`${commonStyles.button} ${styles.editButton}`}>
                    Edit Selections
                  </button>
                )}
              </>
            )}
          </div>
        ) : (
          <div className={styles.noCurrentSelections}>
            <p className={styles.warningMessage}>
              You haven't made selections for this gameweek yet. 
              {!isDeadlinePassed ? " Don't forget to submit your selections before the deadline!" : " The deadline has passed for this gameweek."}
            </p>
          </div>
        )}
        
        {archivedSelections.length > 0 && (
          <div className={styles.archivedSelections}>
          <h3>Archived Selections</h3>
          {archivedSelections.map(selection => (
            <div key={selection.gameweek} className={styles.gameweekContainer}>
              <h4 className={styles.gameweekTitle}>{selection.gameweek}</h4>
              {selection.selections.map((pick, index) => (
                <div key={index} className={styles.selectionItem}>
                  <div className={styles.selectionDetails}>
                    <div className={styles.teamInfo}>
                      {pick.selectedTeam} (Handicap: {pick.handicap})
                    </div>
                    <div className={styles.matchInfo}>
                      {pick.match ? `${pick.match.homeTeam} vs ${pick.match.awayTeam}` : 'N/A'}
                    </div>
                  </div>
                  <div className={`${styles.statusInfo} ${pick.result === 'Win' ? styles.statusWin : styles.statusLoss}`}>
                    {pick.result || 'Pending'}
                  </div>
                </div>
              ))}
              <div className={styles.score}>Score: {selection.score}</div>
            </div>
          ))}
        </div>
        )}

        {selections.length === 0 && <p className={styles.noSelections}>No selections found.</p>}
      </div>
    </Card>
  );
}

function EditSelectionsForm({ selections, matches, onSave, onCancel }) {
  const [editedSelections, setEditedSelections] = useState(() => {
    return selections.map(s => ({
      match: s.match._id,
      selectedTeam: s.selectedTeam,
      handicap: s.handicap
    }));
  });

  useEffect(() => {
    console.log('Current editedSelections:', editedSelections);
  }, [editedSelections]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (editedSelections.length !== 5) {
      alert("Please make exactly 5 selections.");
      return;
    }
    onSave(editedSelections);
  };

  const handleSelectionChange = (matchId, team, handicap) => {
    setEditedSelections(prevSelections => {
      const existingIndex = prevSelections.findIndex(s => s.match === matchId);
      let newSelections;

      if (existingIndex !== -1) {
        // If the same team is clicked again, remove it
        if (prevSelections[existingIndex].selectedTeam === team) {
          newSelections = prevSelections.filter((_, index) => index !== existingIndex);
        } else {
          // Update existing selection
          newSelections = prevSelections.map((s, index) => 
            index === existingIndex ? { match: matchId, selectedTeam: team, handicap } : s
          );
        }
      } else if (prevSelections.length < 5) {
        // Add new selection
        newSelections = [...prevSelections, { match: matchId, selectedTeam: team, handicap }];
      } else {
        // If already 5 selections, don't add more
        return prevSelections;
      }

      return newSelections;
    });
  };

  return (
    <form onSubmit={handleSubmit} className={styles.editForm}>
      <p>Select exactly 5 teams. Current selections: {editedSelections.length}/5</p>
      <div className={styles.matchList}>
        {matches.map((match) => (
          <div key={match._id} className={styles.matchItem}>
            <button
              type="button"
              onClick={() => handleSelectionChange(match._id, match.awayTeam, match.awayHandicap)}
              className={`${styles.teamButton} ${editedSelections.some(s => s.match === match._id && s.selectedTeam === match.awayTeam) ? styles.selected : ''}`}
            >
              {match.awayTeam} ({match.awayHandicap})
            </button>
            <span className={styles.vsLabel}>@</span>
            <button
              type="button"
              onClick={() => handleSelectionChange(match._id, match.homeTeam, match.homeHandicap)}
              className={`${styles.teamButton} ${editedSelections.some(s => s.match === match._id && s.selectedTeam === match.homeTeam) ? styles.selected : ''}`}
            >
              {match.homeTeam} ({match.homeHandicap})
            </button>
            <span className={styles.kickoffTime}>{new Date(match.kickoffTime).toLocaleString()}</span>
          </div>
        ))}
      </div>
      <div className={styles.formButtons}>
        <button type="submit" className={commonStyles.button} disabled={editedSelections.length !== 5}>Save</button>
        <button type="button" onClick={onCancel} className={`${commonStyles.button} ${styles.cancelButton}`}>Cancel</button>
      </div>
    </form>
  );
}

export default MySelections;